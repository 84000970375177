/**
 * Sentry types are a pain to work with so this is a simple local partial copy. Take care in making
 * sure that any strings here line up with Sentry severity levels.
 */
export type SeverityLevel = 'debug' | 'error' | 'fatal' | 'warning';

/**
 * Fired when a trace event is dispatched for capturing.
 */
export type TraceCapturedEvent = CustomEvent<{
  /**
   * The error to capture in Sentry
   */
  error: any;

  /**
   * Optional extra details about the error
   */
  extra?: Record<string, any>;

  /**
   * Optional severity level
   */
  level?: SeverityLevel;
}>;

declare global {
  interface WindowEventMap {
    'trace-captured': TraceCapturedEvent;
  }
}

/**
 * Capture an error event.
 *
 * The problem this function solves is that it allows us to capture error data in Sentry without
 * loading the Sentry library and calling captureException. Unfortunately the Sentry library is
 * incredibly bloated and we use multiple entry points without nice chunking (e.g. dynamic is not
 * very feasible if lazy loaded on page unload) so statically or dynamically importing Sentry harms
 * performance, either in page load time or accuracy in capturing errors still in the queue on page
 * unload. In addition, the console data, which is another way of getting errors into Sentry, has
 * shortcomings such as doing a bad job of capturing extra data about the error. So, here is
 * another alternative. We send the error along with the details of a custom event. The Sentry
 * integration can be coded to listen to this event and do a real error capture instead of a console
 * capture so extra data can be provided.
 *
 * @param value
 * @param extra
 */
export function error(value: any, extra?: Record<string, any>) {
  dispatch(value, extra);
}

/**
 * Capture an error event at the warning level
 *
 * @param value an error value
 * @param extra additional information about the error
 */
export function warn(value: any, extra?: Record<string, any>) {
  dispatch(value, extra, 'warning');
}

function dispatch(error: any, extra?: Record<string, any>, level?: SeverityLevel) {
  // This try/catch is excessive paranoia. However, we have a lot of try/catch logic that uses
  // trace.error or trace.warn as the error handling logic on error. We have to guarantee that the
  // error handling logic itself does not also error. This logic is never expected to error, but if
  // there is some remote chance of that happening, e.g. CustomEvent is not defined or something,
  // some listener is serializing the event data but the event data has non-serializable code, or
  // something like this, then we want to be extra safe.

  try {
    dispatchEvent(new CustomEvent('trace-captured', {
      cancelable: false,
      detail: {
        error,
        extra,
        level
      }
    }));
  } catch (error) {
    if (window.console && typeof console.error === 'function') {
      console.error(error);
    }
  }
}
