import './webpack-path.js';
import { appendStyleModule } from './css-dom.js';

export type Route = keyof typeof routes;

/**
 * Contains a mapping of a route handle to a route import function.
 *
 * A route handle is an easy to use reference name to refer to a particular module to import.
 *
 * This is not an exhaustive list of all modules in the code base. This only contains certain
 * modules that we want to load using the global lazy function.
 *
 * There is no need to catch import errors here. This map is only used by the lazy function which
 * handles promise rejections. Do not use `import().catch(...)` here.
 *
 * Routes must be explicit static paths. Webpack does not work well when using variables. While it
 * is possible, we try to avoid it. Most frontend frameworks also require the same. As a result,
 * we must explicitly list out every possible path. This does add some overhead to the initial file
 * size but we think it is an acceptable amount of overhead.
 */
export const routes = {
  /* eslint-disable @stylistic/max-len */
  'ab-test': () => import('../layout/theme-money-back-guarantee/ab-test.js'),
  'about-us.css': () => import('../page/about-us.scss').then(appendStyleModule),
  'accordion-element': () => import('../element/accordion/index.js'),
  'accordion-item-element': () => import('../element/accordion-item/index.js'),
  'auth': () => import('../auth/index.js'),
  'account-addresses': () => import('../template/customer/addresses.js'),
  'account-login': () => import('../template/customer/login.js'),
  'account-register': () => import('../template/customer/register.js'),
  'add-to-cart-bundle': () => import('../element/add-to-cart-bundle/index.js'),
  'add-to-cart-button': () => import('../element/add-to-cart-button/index.js'),
  'add-to-cart-element': () => import('../element/add-to-cart/index.js'),
  'add-to-cart-notify-me-button': () => import('../element/add-to-cart-notify-me-button/index.js'),
  'alert-element': () => import('../element/alert/index.js'),
  'attentive-pixel': () => import('../pixel/attentive.js'),
  'badges.css': () => import('../snippet/badges.scss').then(appendStyleModule),
  'back-in-stock-pixel': () => import('../pixel/back-in-stock.js'),
  'banner-3d-carousel-section': () => import('../section/banner-3d-carousel/index.js'),
  'banner-element': () => import(/* webpackMode: "eager" */ '../element/banner/index.js'),
  'banner-full-width-carousel': () => import('../section/banner-full-width-carousel.js'),
  'banner-side-by-side-element': () => import('../element/banner-side-by-side/index.js'),
  'banner-tracking': () => import('../banner-tracking.js'),
  'banner-video-element': () => import(/* webpackMode: "eager" */ '../element/banner-video/index.js'),
  'banner-why-are-we-trusted': () => import('../section/banner-why-we-are-trusted/index.js'),
  'before-after-section': () => import('../section/before-after/index.js'),
  'card-grid-element': () => import('../element/card-grid/index.js'),
  'cart-drawer-section': () => import('../section/cart-drawer/index.js'),
  'cart-counter-icon-element': () => import(/* webpackMode: "eager" */ '../element/cart-counter-icon/index.js'),
  'cart-init': () => import('../cart-init.js'),
  'collection-nav-carousel-section': () => import('../section/collection-nav-carousel/index.js'),
  'collection-template': () => import('../template/collection/index.js'),
  'collection-view-observer': () => import('../collection-view-observer/index.js'),
  'compare-before-after-section': () => import('../section/compare-before-after/index.js'),
  'complex-product-comparison-section': () => import('../section/complex-product-comparison/index.js'),
  'configurable-review-element': () => import('../element/configurable-review/index.js'),
  'countdown-clock-element': () => import('../element/countdown-clock/index.js'),
  'countdown-clock-face-circle-element': () => import('../element/countdown-clock-face-circle/index.js'),
  'countdown-clock-face-square-element': () => import('../element/countdown-clock-face-square/index.js'),
  'country-selector-form-element': () => import('../element/country-selector-form/index.js'),
  'country-selector-modal-cta-snippet': () => import('../snippet/country-selector-modal-cta/index.js'),
  'custom-attribute-element': () => import('../element/custom-attribute/index.js'),
  'discount-badge-element': () => import('../element/discount-badge/index.js'),
  'discount-input-element': () => import('../element/discount-input/index.js'),
  'dynamic-header-script': () => import('../section/header/dynamic-header-script.js'),
  'eko-video': () => import('../layout/theme-money-back-guarantee/eko-video.js'),
  'footer-section': () => import('../section/footer/index.js'),
  'footer-section-lazy': () => import('../section/footer-lazy.js'),
  'fullscreen-gallery': () => import('../element/fullscreen-gallery/index.js'),
  'fullstory': () => import('../pixel/fullstory.js'),
  'gift-card-code-element': () => import('../element/gift-card-code/index.js'),
  'hair-quiz-template': () => import('../template/hair-quiz.js'),
  'hamburger-icon-element': () => import(/* webpackMode: "eager" */ '../element/hamburger-icon/index.js'),
  'header-section': () => import(/* webpackMode: "eager" */ '../section/header/index.js'),
  'help-center-page': () => import('../page/help-center/index.js'),
  'help-widget-element': () => import('../element/help-widget/index.js'),
  'help-widget-modal-element': () => import('../element/help-widget-modal/index.js'),
  'how-to-carousel-snippet': () => import('../snippet/how-to-carousel.js'),
  'image-element': () => import('../element/image/index.js'),
  'installment-payment-message-element': () => import('../element/installment-payment-message/index.js'),
  'klaviyo-pixel': () => import('../pixel/klaviyo.js'),
  'loading-element': () => import('../element/loading/index.js'),
  'menu-element': () => import(/* webpackMode: "eager" */ '../element/menu/index.js'),
  'meta-pixel': () => import('../pixel/meta.js'),
  'micromodal.css': () => import('../micromodal.scss').then(appendStyleModule),
  'notify-me-modal-element': () => import('../element/notify-me-modal/index.js'),
  'price-element': () => import('../element/price/index.js'),
  'privacy-page': () => import('../page/privacy.js'),
  'product-card': () => import('../element/product-card/index.js'),
  'product-carousel': () => import('../product-carousel.js'),
  'product-detail-countdown-timer-element': () => import('../element/product-detail-countdown-timer/index.js'),
  'product-detail-value-sets-image-gallery-snippet': () => import('../snippet/product-detail-value-sets-image-gallery.js'),
  'product-detail-value-sets-section': () => import('../section/product-detail-value-set.js'),
  'product-discount-message-element': () => import('../element/product-discount-message/index.js'),
  'product-element': () => import('../element/product/index.js'),
  'product-image-gallery-snippet': () => import('../snippet/product-image-gallery.js'),
  'product-gallery': () => import('../section/product-gallery.js'),
  'product-nav-element': () => import('../element/product-nav/index.js'),
  'product-option-element': () => import('../element/product-option/index.js'),
  'product-option-group-element': () => import('../element/product-option-group/index.js'),
  'product-price-element': () => import('../element/product-price/index.js'),
  'product-quote-element': () => import('../element/product-quote/index.js'),
  'product-quotes': () => import('../section/product-quotes/index.js'),
  'product-rating-stars-element': () => import('../element/product-rating-stars/index.js'),
  'product-step-element': () => import('../element/product-step/index.js'),
  'product-steps-element': () => import('../element/product-steps/index.js'),
  'product-template': () => import('../template/product/index.js'),
  'product-variant-select-element': () => import('../element/product-variant-select/index.js'),
  'product-variant-selector-modal-element': () => import('../element/product-variant-selector-modal/index.js'),
  'real-results-template': () => import('../template/real-results.js'),
  'redeem-points-form-element': () => import('../element/redeem-points-form/index.js'),
  'recommendations-carousel': () => import('../section/recommendations-carousel/index.js'),
  'rewards-balance.css': () => import('../section/rewards-balance.scss').then(appendStyleModule),
  'rewards-template': () => import('../template/rewards/index.js'),
  'refer-a-friend-init': () => import('../section/refer-a-friend-init.js'),
  'sale-banners': () => import('../sale-banners.js'),
  'scrollable-table-element': () => import('../element/scrollable-table/index.js'),
  'search-product-preview-element': () => import('../element/search-product-preview/index.js'),
  'select-option-button-element': () => import('../element/select-option-button/index.js'),
  'sentry': () => import('../pixel/sentry/index.js'),
  'share-a-sale-pixel': () => import('../pixel/share-a-sale.js'),
  'share-element': () => import('../element/share/index.js'),
  'shopify-search-input-element': () => import('../element/shopify-search-input/index.js'),
  'shopify-web-pixel-relay': () => import('../pixel/shopify-web-pixel-relay.js'),
  'side-menu.css': () => import('../section/side-menu.scss').then(appendStyleModule),
  'special-sale-ab-snippet.css': () => import('../snippet/special-sale-ab.scss').then(appendStyleModule),
  'sticky-cta-element': () => import('../element/sticky-cta/index.js'),
  'sticky-product': () => import('../template/product/sticky-product.js'),
  'sticky-footer': () => import('../layout/theme-money-back-guarantee/sticky-footer.js'),
  'twelve-days-gift-card-element': () => import('../element/twelve-days-gift-card/index.js'),
  'typeform-element': () => import('../element/typeform/index.js'),
  'user-quotes-carousel-section': () => import('../section/user-quotes-carousel/index.js'),
  'variant-element': () => import('../element/variant/index.js'),
  'variant-option-group-element': () => import('../element/variant-option-group/index.js'),
  'variant-options-element': () => import('../element/variant-options/index.js'),
  'yotpo-avatar-element': () => import('../element/yotpo-avatar/index.js'),
  'yotpo-product-reviews-section': () => import('../section/yotpo-product-reviews/index.js'),
  'yotpo-refer-a-friend-element': () => import('../element/yotpo-refer-a-friend/index.js'),
  'yotpo-reviews-detail-actions-element': () => import('../element/yotpo-reviews-detail-actions/index.js'),
  'yotpo-review-detail-element': () => import('../element/yotpo-review-detail/index.js'),
  'yotpo-review-element': () => import('../element/yotpo-review/index.js')
  /* eslint-enable @stylistic/max-len */
};
