import { collapse, expand } from './a11y.js';
import * as shroud from './shroud.js';
import * as attentive from './vendor/attentive.js';

/**
 * @todo cart seems to be no longer in use, but there is logic checking for it in cart drawer,
 * investigate and possibly remove
 */
export type MenuName = 'account-menu' | 'cart' | 'shop-menu' | 'upsell';

/**
 * If you are working with the cart drawer, please use the cart drawer decoration library.
 *
 * Never interact with document body's data attributes directly. Always use this library, or a
 * decoration of this library.
 *
 * @param menuName the shorthand name for the menu to close (not the same as the element id)
 */
export function close(menuName: MenuName) {
  shroud.hide();
  document.body.removeAttribute('data-menu-open');

  if (menuName === 'shop-menu' || menuName === 'account-menu') {
    const sideMenu = document.querySelector('menu-element');
    sideMenu.classList.remove('expanded');
    sideMenu.classList.add('collapsed');
    sideMenu.dataset.active = 'shop-menu';

    const hamburgerIcon = document.querySelector('hamburger-icon');
    if (hamburgerIcon) {
      hamburgerIcon.dataset.opened = 'false';
    }
  } else if (menuName === 'upsell') {
    const upsellModal = document.getElementById('upsell');
    if (upsellModal) {
      upsellModal.classList.remove('expanded');
      upsellModal.classList.add('collapsed');
      upsellModal.removeEventListener('animationend', onUpsellAnimationEnded);
    }
  } else {
    collapse(document.getElementById('header-link-shop'));
  }

  document.getElementById('header-link-shop')?.focus();

  attentive.showModalIfActive();
}

/**
 * Returns whether the given menu is currently the open menu.
 *
 * If you are working with the cart drawer, please use the cart drawer decoration library.
 *
 * Never interact with document body's data attributes directly. Always use this library, or a
 * decoration of this library.
 */
export function isOpen(menuName: string) {
  return document.body.dataset.menuOpen === menuName;
}

/**
 * Returns whether any menu is open
 *
 * Never interact with document body's data attributes directly. Always use this library, or a
 * decoration of this library.
 */
export function isAnyOpen() {
  return document.body.dataset.menuOpen ? true : false;
}

/**
 * Closes whatever is the currently open menu
 *
 * Never interact with document body's data attributes directly. Always use this library, or a
 * decoration of this library.
 */
export function closeAnyOpen() {
  close(<MenuName>document.body.dataset.menuOpen);
}

/**
 * If you are working with the cart drawer, please use the cart drawer decoration library.
 *
 * Never interact with document body's data attributes directly. Always use this library, or a
 * decoration of this library.
 */
export function open(menuName: MenuName) {
  const sideMenu = document.querySelector('menu-element');

  // MBG landing pages do not include the main side menu meaning we must confirm we are also not on
  // one of those landing pages before initiating this short circuit.

  // TODO: Once dynamic templates are implemented for MBG and old liquid files are
  // removed the checks against template "product.money-back-guarantee"

  // TODO: this is not the correct place for this logic. do not place use case specific logic in a
  // general helper function. these concerns should be addressed exclusively by the calling code,
  // no this code. this needs to be refactored.

  if (!sideMenu && !lange.template.template.includes('page.money-back-guarantee')) {
    return;
  }

  if (document.body.dataset.menuOpen) {
    close(<MenuName>document.body.dataset.menuOpen);
  }

  document.body.dataset.menuOpen = menuName;
  shroud.show();

  // TODO all this conditions seems overcomplicated and ugly to me. Maybe we should think of
  // refactoring the menu library and managing the side menu, cart drawer, and upsell modal from
  // the one place.

  if (menuName === 'upsell') {
    const upsellModal = document.getElementById('upsell');
    if (upsellModal) {
      // The `.hidden` class added in the liquid template prevents flashing the upsell modal content
      // at the bottom of the page when the page is loading. There's no need to add this class again
      // because after the <upsell-modal> component fully loaded it contains styles for hiding
      // itself already.

      // TODO: Review and update need to utilize class names and instead use existing, configured
      // dataset values to drive required styles.
      upsellModal.classList.remove('hidden');
      upsellModal.classList.remove('collapsed');
      upsellModal.classList.add('expanded');
      upsellModal.addEventListener('animationend', onUpsellAnimationEnded);
    }
  } else {
    const menuEl = document.querySelector('menu-element');
    menuEl.classList.remove('collapsed');
    menuEl.classList.add('expanded');
    menuEl.dataset.active = menuName;

    // this focus trapping approach doesn't work with the upsell modal, therefor I've moved it
    // to the function and call only for main menu
    trapFocus(menuName);
  }

  if (menuName === 'shop-menu' || menuName === 'account-menu') {
    const hamburgerIcon = document.querySelector('hamburger-icon');
    if (hamburgerIcon) {
      hamburgerIcon.dataset.opened = 'true';
    }
  } else {
    expand(document.getElementById('header-link-shop'));
  }

  // We have to check for two conditions:
  // * the sideMenu is defined, because in some cases such as when opening the cart on
  //   /pages/le-duo-mbg the variable is not defined
  // * the sideMenu element has a scroll function, because in some older browsers there is not one

  // TODO: just set scrollTop (and maybe scrollLeft?), avoid using this function.

  if (typeof sideMenu?.scroll === 'function') {
    sideMenu.scroll(0, 0);
  }

  attentive.hideModalIfActive();
}

function trapFocus(menuName: string) {
  // Set tab trap
  const container = document.querySelector<HTMLElement>(`#${menuName}`);
  if (container) {
    // Set bounds
    const selector = [
      'input:not([tabindex^="-"]):not([disabled])',
      'select:not([tabindex^="-"]):not([disabled])',
      'textarea:not([tabindex^="-"]):not([disabled])',
      'button:not([tabindex^="-"]):not([disabled])',
      'a[href]:not([tabindex^="-"]):not([disabled])',
      '[tabindex]:not([tabindex^="-"]):not([disabled])'
    ].join(',');

    const boundsElements = container.querySelectorAll<HTMLElement>(selector);
    const first = boundsElements[0];
    const last = boundsElements[boundsElements.length - 1];
    first?.focus();

    container.addEventListener('keydown', event => {
      if (event.key === 'Tab') {
        const previous = document.activeElement;

        if (event.shiftKey) {
          if (previous === first) {
            event.preventDefault();
            last?.focus();
          }
        } else if (previous === last) {
          event.preventDefault();
          first?.focus();
        }
      }
    });
  }
}

function onUpsellAnimationEnded(event: AnimationEvent) {
  // before animation focus cannot be set because the element has `visibility: hidden`, we need to
  // wait till the animation end
  const upsellModal = <HTMLElement>event.currentTarget;
  upsellModal.focus();
}
