import type { CustomElement } from '../../lib/custom-element.js';
import html from './index.html';
import styles from './index.scss';

/**
 * @todo document me, what does this element do in summary?
 * @todo the html in the shadow root should not use a root element
 */
class BannerVideo extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-title',
      'data-copy',
      'data-video-vendor',
      'data-video-id',
      'data-background-image',
      'data-product-image'
    ];
  }

  readonly dataset!: {
    /**
     * Background image url for the banner element.
     */
    backgroundImage: string;

    /**
     * Banner copy text.
     */
    copy: string;

    /**
     * Image url for banner element.
     */
    productImage: string;

    /**
     * Banner title.
     */
    title: string;

    /**
     * Id of the video from youtube or eko website.
     *
     * @example nO1UOZ6bLkk
     */
    videoId: string;

    /**
     * Vendor of the video, render appropriate player widget (youtube or eko).
     */
    videoVendor: string;
  };

  private onResizeBound = this.onResize.bind(this);

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    addEventListener('resize', this.onResizeBound);
    this.render();
  }

  public disconnectedCallback() {
    removeEventListener('resize', this.onResizeBound);
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && newValue !== oldValue) {
      this.render();
    }
  }

  private render() {
    const headerEl = this.shadowRoot.querySelector('h1');
    headerEl.textContent = this.dataset.title;

    const copyEl = this.shadowRoot.querySelector('p');
    copyEl.innerHTML = this.dataset.copy;

    if (this.dataset.videoVendor === 'youtube') {
      this.renderYouTube();
    } else if (this.dataset.videoVendor === 'eko') {
      this.renderEko();
    }

    const aspectRatio = 982 / 1920;
    let width = innerWidth;
    let height = innerWidth * aspectRatio;
    if (innerWidth > 1600) {
      height = 1600 * aspectRatio;
      width = 1600;
    }

    const backgroundEl = this.shadowRoot.querySelector<HTMLElement>('.background-image');
    if (innerWidth > 830) {
      backgroundEl.style.backgroundImage = `url('${this.dataset.backgroundImage}')`;
      backgroundEl.style.height = `${height}px`;
      backgroundEl.style.width = `${width}px`;
    } else {
      backgroundEl.style.backgroundImage = 'none';
      backgroundEl.style.height = 'auto';
      backgroundEl.style.width = '100%';
    }

    if (this.dataset.productImage) {
      const image = this.shadowRoot.querySelector('img');
      image.src = this.dataset.productImage;
      image.alt = this.dataset.title;
    }
  }

  private renderYouTube() {
    let iframe = this.shadowRoot.querySelector<HTMLIFrameElement>('#youtube-iframe');
    if (!iframe) {
      const videoUrl = new URL(`https://www.youtube.com/embed/${this.dataset.videoId}`);
      videoUrl.searchParams.set('controls', '0');

      iframe = document.createElement('iframe');
      iframe.id = 'youtube-iframe';
      iframe.dataset.videoId = this.dataset.videoId;
      iframe.title = this.dataset.title;
      iframe.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;' +
      'picture-in-picture; web-share';
      iframe.allowFullscreen = true;
      iframe.src = videoUrl.toString();

      const videoEl = this.shadowRoot.querySelector('.video');
      videoEl.innerHTML = '';
      videoEl.appendChild(iframe);
    } else if (iframe.dataset.videoId !== this.dataset.videoId) {
      const videoUrl = new URL(`https://www.youtube.com/embed/${this.dataset.videoId}`);
      videoUrl.searchParams.set('controls', '0');
      iframe.src = videoUrl.toString();
      iframe.dataset.videoId = this.dataset.videoId;
      iframe.title = this.dataset.title;
    }
  }

  private renderEko() {
    let script = this.shadowRoot.querySelector<HTMLScriptElement>('#eko-script');
    if (!script) {
      const videoUrl = new URL('https://play.eko.com/embed/v1.js');
      videoUrl.searchParams.set('id', this.dataset.videoId);

      script = document.createElement('script');
      script.id = 'eko-script';
      script.dataset.videoId = this.dataset.videoId;
      script.src = videoUrl.toString();
      script.async = true;

      const innerDiv = document.createElement('div');
      innerDiv.classList.add('eko_responsive_wrapper');
      innerDiv.appendChild(script);

      const outerDiv = document.createElement('div');
      outerDiv.classList.add('eko_responsive_padding');
      outerDiv.appendChild(innerDiv);

      const videoEl = this.shadowRoot.querySelector('.video');
      videoEl.innerHTML = '';
      videoEl.appendChild(outerDiv);
    } else if (script.dataset.videoId !== this.dataset.videoId) {
      const videoUrl = new URL('https://play.eko.com/embed/v1.js');
      videoUrl.searchParams.set('id', this.dataset.videoId);
      script.src = videoUrl.toString();
    }
  }

  private onResize(_event?: UIEvent) {
    this.render();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'banner-video': BannerVideo;
  }
}

if (!customElements.get('banner-video')) {
  customElements.define('banner-video', BannerVideo);
}
